
import './App.css';

function App() {
  return (
    <>
    <div class="wrapper"></div>
  <div class="stars1"></div>
  <div class="stars2"></div>
  <div class="stars3"></div>
  <main className="main">
<section className="contact">
   <h1 className="title">The Place to  find Banking Deals</h1>
   <h2 className="sub-title">Banking Deals...  coming soon.........</h2>
 </section>

 <div className='imgmain'>
   <img  className='bannerimage' src="imgfolder/banner6.gif" alt="" />
 </div>
 
</main>




   
    </>
  );
}

export default App;
