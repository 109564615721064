import React from 'react'
import "./Header.css"
const Header = () => {
  return (
    <div className='div1'>
     <img className='logo' src="Imgfolder/mainlogo.png" alt="bankingdeals" />   
    </div>
  )
}

export default Header