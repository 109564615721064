import React from 'react'
import "./Footer.css"
const Footer = () => {
  return (
    <div className='div2'>
      &copy;{new Date().getFullYear()} , All rights reserved by BD  
    </div>
  )
}

export default Footer